<template>
    <div>
        <div class="filter-wrap">
            <!-- 筛选器组件 -->
            <expand-filter
                :form-data="formData"
                margin-bottom="0"
                @clickBtn="clickBtn"
                @changeBtnFormType="changeBtnFormType"
            />
            <div class="button-line" v-has-permi="['checkattendance:dailyanalysis:export']"></div>
            <div class="button-group">
                <div class="left-button-group">
                    <el-button
                        v-has-permi="['patrolProgramme:add']"
                        type="primary"
                        @click="handleAdd"
                    >
                        添加
                    </el-button>
                </div>
            </div>
        </div>

        <!-- 表格数据 -->
        <table-data
            ref="table"
            v-loading="tableLoading"
            :to-top="false"
            :config="table_config"
            :table-data="table_data"
        >
            <template #name="slotData">
                <el-button
                    type="text"
                    @click="toDetail(slotData.data)"
                    class="ellipsis"
                    style="width: 100%"
                >
                    <el-tooltip class="item" effect="dark" :content="slotData.data.name" :disabled="slotData.data.name.length < 18" placement="top-start">
                        <span>{{ slotData.data.name || '-' }}</span>
                    </el-tooltip>
                </el-button>
            </template>
            <!-- 自定义操作列 -->
            <template #operation="slotData">
                <el-button
                    v-has-permi="['patrolProgramme:edit']"
                    type="text"
                    @click="handleEdit(slotData.data.id)"
                >
                    编辑
                </el-button>
                <el-button
                    v-if="slotData.data.status == 1"
                    v-has-permi="['patrolProgramme:delete']"
                    type="text"
                    style="color: #e63a2e !important"
                    @click="handleRevoke(slotData.data)"
                >
                    删除
                </el-button>
            </template>
        </table-data>

        <!-- 分页组件 -->
        <Pagination
            :total="total"
            :page.sync="listQuery.pageNum"
            :limit.sync="listQuery.pageRow"
            @pagination="changeGetList"
        />
    </div>
</template>

<script>
import ExpandFilter from "@/components/scrollWrapper/Sub/ExpandFilter";
import { Pagination } from "common-local";
import { mapState } from "vuex";
import TableData from "../Sub/TableData";
import onResize from "@/mixins/onResize";
import { formatTreeData } from "@/libs/utils";

export default {
    components: {
        Pagination,
        ExpandFilter,
        TableData,
    },
    mixins: [onResize],
    data() {
        return {
            tableLoading: false, // 表格加载状态
            // 筛选器和表格的配置数据
            formData: {
                data: [
                    {
                        type: "input",
                        value: "",
                        placeholder: "方案名称",
                        key: "name",
                    },
                    {
                        type: "input",
                        value: "",
                        placeholder: "巡课教师",
                        key: "patrolTeacherCond",
                    },
                    {
                        placeholder: "班级",
                        type: "cascader",
                        key: 'classCond',
                        value: [],
                        organType: "",
                        list: [],
                        cascaderProps: {
                            label: "name",
                            multiple: true,
                            value: "id",
                            emitPath: false
                        },
                        filter(data) {
                            return data.organType !== '1';
                        },
                    },
                    {
                        type: "input",
                        value: "",
                        placeholder: "上课教师",
                        key: "classTeacherCond",
                    },
                    {
                        type: "select",
                        label: "",
                        value: "",
                        placeholder: "状态",
                        key: "status",
                        list: [
                            {
                                label: "暂存",
                                value: 1,
                            },
                            {
                                label: "已发布",
                                value: 2,
                            },
                        ],
                    },
                ],
                btnList: [
                    {
                        type: "primary",
                        text: "查询",
                        fn: "primary",
                        auth: ["patrolProgramme:list"]
                    },
                    {
                        type: "enquiry",
                        text: "重置",
                        fn: "reset"
                    },
                ],
                btnFormType: true,
            },
            // 表格配置
            table_config: {
                thead: [
                    {
                        label: "方案名称",
                        type: "slot",
                        labelWidth: "300",
                        minWidth: '300',
                        slotName: "name",
                        align: "center",
                    },
                    {
                        label: "巡课教师",
                        prop: "patrolTeacherList",
                        align: "center",
                        minWidth: '320',
                        labelWidth: "320",
                        type: "tooltipColumn",
                    },
                    {
                        label: "巡课方式",
                        prop: "type",
                        align: "center",
                        type: "function",
                        callBack: (row) => {
                            return {
                                1: '自由巡课',
                                2: '固定课程巡课',
                            }[+row.type]
                        },
                    },
                    {
                        label: "巡课对象",
                        prop: "targetList",
                        minWidth: '320',
                        labelWidth: "320",
                        type: "tooltipColumn",
                        align: "center",
                    },
                    {
                        label: "状态",
                        prop: "status",
                        align: "center",
                        labelWidth: "150",
                        className: 'text-spacing-reduction',
                        type: "function",
                        callBack(row) {
                            if (row.status == '1') {
                                return `<div style="display:flex;align-items: center;justify-content: center;"><div style="width: 6px;height: 6px;background: #E63A2E;border-radius: 50%; margin-right: 8px;"></div> <span>暂存</span></div>`
                            } else if (row.status == '2') {
                                return `<div style="display:flex;align-items: center;justify-content: center;"><div style="width: 6px;height: 6px;background: #04CCAB;border-radius: 50%; margin-right: 8px;"></div> <span>已发布</span></div>`
                            } else {
                                return '-'
                            }
                        }
                    },
                    {
                        label: "操作",
                        type: "slot",
                        className: 'text-spacing-reduction',
                        slotName: "operation",
                    },
                ],
                check: false,
                height: "",
            },
            table_data: [], // 表格数据
            total: 0, // 数据总数
            listQuery: {
                name: '',
                patrolTeacherCond: '',
                classCond: [],
                classTeacherCond: '',
                status: '',
                pageNum: 1,
                pageRow: 20,
            },
            firstTime: true,
            tableScroll: null,
        };
    },
    computed: {
        ...mapState({
            schoolId: (state) => state.schoolId,
        }),
    },
    created() {
        this.firstTime = false;
        this.init();
    },
    activated() {
        // 在首次挂载、以及每次从缓存中被重新插入的时候调用
        if (this.firstTime) {
            this.init();
        }
    },
    deactivated() {
        this.firstTime = true;
    },
    methods: {
        // 初始化函数
        init() {
            this.getClassList()
            this.getList();
        },
        // 切换页码时触发
        changeGetList() {
            this.$nextTick(() => {
                this.$refs.table.$refs.multipleTable.bodyWrapper.scrollTop = 0;
            });
            this.getList();
        },
        //获取班级列表
        getClassList() {
            this._fet("/school/schoolOrgan/getAllGradeClassList", {
                schoolId: this.$store.state.schoolId
            }).then((res) => {
                if (res.data.code === '200') {
                    const index = this.formData.data.findIndex((i) => i.key === 'classCond')
                    this.formData.data[index].list =
                        formatTreeData(res.data.data, "id", "parentOrg")
                } else {
                    this.$message.error(res.data.msg)
                }
            });
        },
        // 获取列表数据
        async getList(t) {
            this.tableLoading = true;
            if (t === 1) {
                this.listQuery.pageNum = 1;
            }
            await this._fet("/school/schoolPatrolProgramme/list", this.listQuery).then((res) => {
                if (res.data.code === "200") {
                    this.total = res.data.data.totalCount || 0;
                    this.table_data = res.data.data.list;
                    res.data.data.list.forEach((item) => {
                        let patrolTeacherList = ''
                        item.patrolTeacher.forEach((i, j) => {
                            if (j < item.patrolTeacher.length - 1) {
                            patrolTeacherList = patrolTeacherList + i.name + ', '
                            } else {
                            patrolTeacherList = patrolTeacherList + i.name + ''
                            }
                        })
                        this.$set(item, 'patrolTeacherList', patrolTeacherList)
                    })
                    res.data.data.list.forEach((item) => {
                        let targetList = ''
                        if(item.type == '1'){
                            item.target.forEach((i, j) => {
                                if (j < item.target.length - 1) {
                                targetList = targetList + i.name + ', '
                                } else {
                                targetList = targetList + i.name + ''
                                }
                            })
                        }else if(item.type == '2'){
                            item.target.forEach((i, j) => {
                                if (j < item.target.length - 1) {
                                targetList = targetList + this.handleStr(i.name) + ', '
                                } else {
                                targetList = targetList + this.handleStr(i.name) + ''
                                }
                            })
                        }

                        this.$set(item, 'targetList', targetList)
                    })
                    if (this.tableScroll) {
                        this.$nextTick(() => {
                            this.$refs.table.$refs.multipleTable.bodyWrapper.scrollTop = this.tableScroll.scrollTop
                            this.$refs.table.$refs.multipleTable.bodyWrapper.scrollLeft = this.tableScroll.scrollLeft
                            this.tableScroll = null
                        })
                    }
                } else {
                    this.$message.error(res.data.msg);
                }
            }).finally(() => {
                this.tableLoading = false;
            })
        },
        // 处理固定巡课对象不要时间信息
        handleStr(str){
            let arr = str.split(' ')
            return arr[0] + ' ' + arr[1]
        },
        // 点击筛选器按钮触发的事件
        clickBtn(ev) {
            switch (ev.item.fn) {
                case "primary": // 查询
                    this.formData.data.forEach((item) => {
                        this.listQuery[item.key] = item.value;
                    });
                    this.getList(1);
                    break;
                case "reset": // 重置
                    this.formData.data.forEach((item) => {
                        item.value = "";
                        this.listQuery[item.key] = "";
                        if (item.key == 'classCond') {
                            item.value = [];
                            this.listQuery[item.key] = [];
                        }
                    });
                    this.getList(1);
                    break;
            }
        },
        // 跳转到请假/调休设置页面
        handleSetting() {
            this.$router.push({
                // 假期管理
                name: "CoworkingCampusOAVacationManagement",
                query: {
                    id: 1
                }
            });
        },
        // 处理添加操作
        async handleAdd() {
            await this.getList(1, true)
            this.$emit('switchCom', {com: 'AddAndEdit', editData: null})
        },
        /**
         * @Description: 跳转到详情
         * @Author: pyl
         * @Date: 2024-08-29 17:42:21
         */
        toDetail(data) {
            this.$emit('switchCom', {com: 'Detail', id: data.id})
        },
        // 处理编辑操作
        async handleEdit(id) {
            this._fet('/school/schoolPatrolProgramme/getDetailById', {id}).then(res => {
                console.log('巡课方案详情：',res)
                if(res.data.code == 200){
                    let data = res.data.data
                    // 详情数据处理---教师信息
                    this.contentList = data.map
                    const keyArrTeacherId =  this.contentList.hasOwnProperty('1') ? Object.keys(this.contentList[1]) : []
                    // 学生信息
                    const keyArrStuId =  this.contentList.hasOwnProperty('2') ? Object.keys(this.contentList[2]) : []
                    const keyArrTeacher = []
                    keyArrTeacherId.forEach((item, index) => {
                        keyArrTeacher.push({
                            id: this.contentList[1][item][0].contentId,
                            content: this.contentList[1][item][0].contentName,
                            type: this.contentList[1][item][0].type,
                            conSort: this.contentList[1][item][0].conSort,
                            list: []
                        })
                        this.contentList[1][item].forEach(i => {
                            keyArrTeacher[index].list.push({
                                id: i.setId,
                                name: i.setName,
                                setSort: i.setSort,
                                score: i.setScore,
                                groupType: i.groupType,
                                lowScore: i.lowScore,
                                highScore: i.highScore,
                            })
                        })
                    })
                    const keyArrStu = []
                    keyArrStuId.forEach((item, index) => {
                        keyArrStu.push({
                            id: this.contentList[2][item][0].contentId,
                            content: this.contentList[2][item][0].contentName,
                            type: this.contentList[2][item][0].type,
                            conSort: this.contentList[2][item][0].conSort,
                            list: []
                        })
                        this.contentList[2][item].forEach(i => {
                            keyArrStu[index].list.push({
                                id: i.setId,
                                name: i.setName,
                                setSort: i.setSort,
                                score: i.setScore,
                                groupType: i.groupType,
                                lowScore: i.lowScore,
                                highScore: i.highScore,
                            })
                        })
                    })
                    this.$emit('switchCom', {com: 'AddAndEdit', editData: data, keyArrTeacher, keyArrStu})
                }else if(res.data.msg){
                    this.$message.error(res.data.msg)
                }
            })
        },
        /**
         * @Description: 删除
         * @Author: pyl
         * @Date: 2024-08-28 16:46:01
         */
        handleRevoke(data) {
            console.log('删除的数据', data)
            this.$confirm("提示", {
                message: '该方案删除后将不可恢复，是否继续？',
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    const param = {
                        id: data.id,
                    };
                    this._fet("/school/schoolPatrolProgramme/delete", param).then((res) => {
                        this.handleRes(res, () => {
                            this.$message.success("删除成功!");
                            this.getList(1);
                        });
                    });
                })
                .catch(() => {
                    // 取消
                });
        },
        /**
         * @Description: 接口请求结果处理
         * @Author: pyl
         * @Date: 2024-08-28 16:51:50
         */
        handleRes(res, fn) {
            if (res.data.code === "200") {
                fn();
            } else if (res.data && res.data.msg) {
                this.$message.error(res.data.msg);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.filter-wrap {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    background-color: #fff;
    padding: 10px 10px 0;
    margin: 10px 0;
    border-radius: 4px;
    overflow: hidden;

    .expand-filter {
        padding: 0;
    }

    .button-group {
        display: flex;
        flex: 1;
        justify-content: space-between;
        white-space: nowrap;
    }

    .left-button-group {
        display: flex;
        flex-shrink: 0;
    }
    .right-button-group {
        display: flex;
        flex-shrink: 0;

        .upload-demo {
            margin-left: 10px;
        }

        .button-export {
            margin-left: 10px;
        }
    }
}

::v-deep .is-loading .el-icon-loading {
    margin-right: 0;
}

.import-wrap {
    margin-bottom: 20px;
}
</style>
